import { Interweave } from 'interweave'
import React, { MouseEventHandler, ReactElement } from 'react'
import { Container } from 'styled-bootstrap-grid'
import styled, { StyledProps } from 'styled-components'
import { Article } from './Article'
import ContentBlock from './ContentBlock'
import StyledContainer from './StyledContainer'

interface ArticleContentBlockProps {
  header?: string
  text?: string
  id?: string
  title?: string
  lightTheme?: boolean
  children?: React.ReactNode
  image?: ReactElement
  siblings?: ReactElement
  onClick?: MouseEventHandler<Element>
}

export const ArticleContentBlock = ({
  id,
  header,
  text,
  title,
  lightTheme,
  children,
  image,
  siblings,
  onClick,
}: ArticleContentBlockProps): JSX.Element => {
  const Image = () => <>{image}</>

  return (
    <ArticleContainer lightTheme={lightTheme}>
      <Container id={id}>
        <Article>
          <BlockContent header={header} title={title} onClick={onClick}>
            <ArticleContent image={image}>
              {Boolean(image) && <Image />}
              {Boolean(text) && (
                <Text image={image}>
                  <Interweave content={text} />
                </Text>
              )}
            </ArticleContent>
            {children}
          </BlockContent>
          {siblings}
        </Article>
      </Container>
    </ArticleContainer>
  )
}

const Text = styled.p`
  ${(props: StyledProps<Pick<ArticleContentBlockProps, 'image'>>) =>
    !props.image &&
    `
    margin-top: 0;
`}

  ${(props: StyledProps<Pick<ArticleContentBlockProps, 'image'>>) =>
    !!props.image &&
    `
    margin-top: 2em;
`}
`

const ArticleContent = styled.div`
  ${(props: StyledProps<Pick<ArticleContentBlockProps, 'image'>>) =>
    !!props.image &&
    `
    align-items: center;
    display: flex;
    flex-direction: column;
`}
`

const ArticleContainer = styled(StyledContainer)``

const BlockContent = styled(ContentBlock)`
  ${(props: StyledProps<Pick<ArticleContentBlockProps, 'onClick'>>) =>
    !!props.onClick &&
    `
    cursor: pointer;
  `}
`
