import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { Header, Title } from './Article'

interface ArticleContentProps extends JSX.ElementChildrenAttribute {
  header?: string
  title?: string
  className?: string
}

export const ArticleContent = ({
  header,
  title,
  children,
  className,
}: ArticleContentProps): JSX.Element => (
  <Article className={className}>
    <Row>
      {header && (
        <Col xs={12} lg={3}>
          <Header>{header}</Header>
        </Col>
      )}
      <Col xs={12} lg={9} lgOffset={!header ? 3 : 0}>
        {title && <Title>{title}</Title>}
        {children}
      </Col>
    </Row>
  </Article>
)

export default ArticleContent

export const Article = styled.article`
  padding-top: 1rem;
  padding-bottom: 1rem;
`
