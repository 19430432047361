import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import FullContainer from './FullContainer'

interface ImpressionProps {
  text: string
}

export const Impression = ({ text }: ImpressionProps): JSX.Element => (
  <StyledContainer>
    <Content>
      <Text>{text}</Text>
    </Content>
  </StyledContainer>
)

export default Impression

const StyledContainer = styled(FullContainer)`
  background: ${({ theme }) => theme.colors.impression};
  padding: 100px 0;
  ${media.lessThan('medium')`
    padding: 30px 0;
`}
`

const Text = styled.p`
  color: black;
  font-size: 2em;
  padding: 0;
  margin: 0 auto;
  max-width: 1100px;
  line-height: 1.6em;
`

const Content = styled.article`
  padding-top: 0;
  margin: 0 10vw;
`
