import styled from 'styled-components'
import media from 'styled-media-query'

export const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.28rem;
  line-height: 1.3;

  ${media.lessThan('large')`
  margin-bottom: 1.5rem;
`}
  ${media.lessThan('medium')`
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 1rem;
`}
`
