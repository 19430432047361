import styled from 'styled-components'
import media from 'styled-media-query'

export const LogoWrapper = styled.div`
  display: inline-block;
  background-size: contain;
  max-width: 150px;
  max-height: 150px;
  padding: 1px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  ${media.lessThan(`medium`)`
    max-width: 100px;
    max-height: 100px;
    margin-right: 16px;
`}
`
