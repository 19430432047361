import { navigate } from 'gatsby'
import React from 'react'
import ArticleContent from '../../components/ArticleContent'
import { ArticleContentBlock } from '../../components/ArticleContentBlock'
import Impression from '../../components/Impression'
import { Layout } from '../../components/Layout'
import { Logo } from '../../components/Logo'
import { LogoWrapper } from '../../components/LogoWrapper'
import SEO from '../../components/SEO'

const navigateToCaseStudy = (caseStudy: string) => {
  navigate(`/case-studies/${caseStudy}`)
}

const Index = (): JSX.Element => (
  <Layout>
    <SEO title="Case Studies" description="Raven Code Case Studies" />
    <Impression
      text="We take immense pride in our work, and these case studies
      highlight some of the remarkable projects we have successfully
      undertaken. Each case study showcases our expertise, problem-solving abilities,
      and the positive outcomes we have delivered to our clients."
    />
    <ArticleContentBlock
      image={
        <LogoWrapper>
          <Logo fill="#fff" />
        </LogoWrapper>
      }
      siblings={
        <ArticleContent header="Website Url">
          <a
            href="https://energyleveltracker.app"
            title="Energy Level App Website"
          >
            https://energyleveltracker.app
          </a>
        </ArticleContent>
      }
      onClick={() => navigateToCaseStudy('energy-level-tracker')}
      header="Energy Level Tracker"
      text='"Energy Level Tracker" is a new personal wellness monitoring
          mobile app that allows users to monitor and optimise their energy
          levels throughout the day. It was developed by the Raven Code team
          using React Native and is available on both Google Play Store and
          Apple&#39;s App Store. The goal was to create a user-friendly
          experience that offers both a seamless and intuitive way to track and
          optimise energy levels.'
    />
  </Layout>
)

export default Index
