import styled from 'styled-components'
import media from 'styled-media-query'

export const Article = styled.article`
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${media.lessThan('large')`
    padding-top: 3rem;
    padding-bottom: 3rem;
  `}
  ${media.lessThan('medium')`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `}
  ${media.lessThan('small')`
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}
`
