import React, { MouseEventHandler } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { Header, Title } from './Article'

interface ContentBlockProps extends JSX.ElementChildrenAttribute {
  header?: string
  title?: string
  onClick?: MouseEventHandler<Element>
  className?: string
}

export const ContentBlock = ({
  header,
  title,
  children,
  onClick,
  className,
}: ContentBlockProps): JSX.Element => (
  <ContentBlockContainer onClick={onClick} className={className}>
    <Row>
      {header && (
        <Col xs={12} lg={3}>
          <Header>{header}</Header>
        </Col>
      )}
      <Col xs={12} lg={9} lgOffset={!header ? 3 : 0}>
        {title && <Title>{title}</Title>}
        {children}
      </Col>
    </Row>
  </ContentBlockContainer>
)

export default ContentBlock

const ContentBlockContainer = styled.div``
