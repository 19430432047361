import styled from 'styled-components'
import media from 'styled-media-query'

export const Header = styled.h4`
  transition: all 0.1s cubic-bezier(0.72, 0.16, 0.345, 0.875);
  opacity: 0.5;
  overflow: hidden;
  font-size: 0.87rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  margin-top: 0.62rem;
  margin-bottom: 0.62rem;
  text-transform: uppercase;
  line-height: 1.2;
  ${media.lessThan('large')`
      margin-bottom: 1rem;
  `}
`
